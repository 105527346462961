a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.admin-menu .dropdown-menu {
    font-size: inherit;
}

.admin-menu .dropdown .dropdown-toggle.nav-link {
    color: inherit;
}

.admin-menu .navbar-nav .nav-item {
    padding: 0 5px;
}

.admin-menu .navbar-nav .nav-item:hover {
    background: #ddd;
    border-radius: 3px;
}

.admin-menu .navbar-nav .nav-item.active {
    border-bottom: 2px solid #0062cc;
}