html {
    font-size: 10px;
    background: #e2e2e2 !important;
    overflow: hidden;
    width: 100%;
}

@media (min-width: 1921px) {
    html {
        font-size: 16px;
    }
}

@media (max-width: 1920.98px) {
    html {
        font-size: 11.2px;
    }
}

@media (max-width: 1679.98px) {
    html {
        font-size: 10px;
    }
}

@media (max-width: 1199.98px) {
    html {
        font-size: 9px;
    }
}

@media (max-width: 991.98px) {
    html {
        font-size: 8px;
    }
}

@media (max-width: 767.98px) {
    html {
        font-size: 9.5px;
    }
}

@media (max-width: 420.98px) {
    html {
        font-size: 8.5px;
    }
}

@media (max-width: 350.98px) {
    html {
        font-size: 7px;
    }
}

body {
    font-size: 1.6rem;
    height: 100%;
    position: fixed;
    overflow-y: scroll;
    /* background: #e2e2e2; */
}

main {
    min-height: calc(100vh - 1px);
    display: flex;
    font-family: 'Poppins', sans-serif;
    line-height: 1;
}

main header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 9;
    background-color: #333333;
    padding: 1rem 1.5rem;
}

main header .logo {
    display: block;
    margin: auto;
}

main header .logo img {
    max-height: 5.5rem;
}

main header .back-btn {
    position: absolute;
}

.front-end-root {
    background: #ffffff;
}

.enterColor {
    color: #ffffff;
}

.cm-container {
    /* background-color: #e2e2e2; */
    min-height: 100vh;
    /* padding: 0 1.5rem;
    height: 100%; 
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;*/
}

.back-btn img {
    max-width: 2.5rem;
    width: 100%;
    height: auto;
}


/*scent test page css start*/

.overlay {
    background-color: #000000;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: 0.1;
    visibility: hidden;
}

.overlay.active {
    visibility: visible;
}

.scent_items {
    padding: 2.5rem 0;
    background-color: #da3832;
    box-shadow: 0px 2px 5px 0px #000;
    z-index: 1;
    position: relative;
}

.scent_items .scent_item {
    background-color: #ffffff;
    border-radius: 100%;
    overflow: hidden;
    width: 13.5rem;
    height: 13.5rem;
    text-align: center;
    border: 0.3rem solid #4c4f55;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    padding: 1rem;
    margin: 0 auto;
    transition: all 0.5s ease-in-out;
}

.scent_items .scent_item:focus,
.scent_items .scent_item.active {
    background-color: #dfdfdf;
}

.scent_items .scent_item img {
    width: 90%;
    height: 70%;
    margin: 0 auto;
    object-fit: contain;
}

.scent_items .scent_item p {
    margin: 0 auto;
    font-weight: 600;
    font-size: 1.4rem;
}

.howto_wrapper {
    padding: 1rem 1.5rem;
    background-color: #333333;
    box-shadow: 0 1px 5px #000;
}

.howto_wrapper .howto_items {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}

.howto_wrapper .howto_items .howto_item {
    list-style-type: none;
    text-align: center;
    margin: 0 2rem;
}

.howto_wrapper .howto_items .howto_item img {
    max-height: 5rem;
}

.howto_wrapper .howto_items .howto_item h6 {
    margin: 0.5rem 0 0;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1;
    color: #ffffff;
}

.steps_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.steps_wrapper .steps {
    padding: 0;
    margin: 2.5rem auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.steps_wrapper .steps .step {
    list-style-type: none;
    text-align: center;
    margin: 1rem;
    background-color: #b1b3b2;
    padding: 1rem 0.5rem;
    border-radius: 0.5rem;
    box-shadow: 0.3rem -0.3rem 0rem 0 #3e3e3e;
}

.steps_wrapper .steps .step_arrow {
    list-style-type: none;
}

.steps_wrapper .steps .step_arrow img {
    max-width: 8rem;
    padding: 0 1rem;
}

.steps_wrapper .steps .step span {
    display: block;
    color: #ffffff;
    line-height: 1;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.2rem;
}

.steps_wrapper .steps .step span.count {
    font-size: 3.2rem;
    line-height: 0.75;
    margin-top: 0.5rem;
}

.steps_wrapper .steps .step.active {
    outline: 0.5rem solid #da3832;
}

.smell_wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    z-index: 3;
}

.smell_wrapper .smell_popup {
    max-width: 90%;
    margin: 2.5rem auto;
    padding: 2.5rem;
    border-radius: 1rem;
    background-color: #ffffff;
    box-shadow: 0 0 10px #b9b9b9;
}

.cm-smellIntensity-modal .smell_popup .ant-radio-wrapper {
    display: inline-block;
    margin-right: 0;
}

.cm-smellIntensity-modal .smell_popup .ant-radio-wrapper span:last-child {
    font-weight: 700;
    padding: 0;
    display: block;
}

.cm-smellIntensity-modal .ant-modal-content {
    border-radius: 12px;
}

.cm-smellIntensity-modal .ant-modal-body {
    padding-top: 12px;
}

.cm-smellIntensity-modal .ant-modal-close {
    right: auto;
    left: 0;
}

.cm-smellIntensity-modal .ant-modal-close .ant-modal-close-x {
    height: 48px;
    width: 48px;
    line-height: 48px;
    font-size: 20px;
    color: #ff0000;
}

.cm-smellIntensity-modal .ant-modal-close .ant-modal-close-x .anticon-close {
    color: #676464;
    border: 0.3rem solid #676464;
    border-radius: 100%;
    padding: 0.5rem;
}

.cm-smellIntensity-modal .popup_head h3 {
    text-transform: uppercase;
    color: #333333;
    font-size: 3.5rem;
    margin-bottom: 0;
    margin-left: 1.5rem;
    text-align: center;
}

.cm-smellIntensity-modal .popup_head img {
    max-width: 3rem;
}

.smell_wrapper .smell_popup .smell_range {
    margin: 2rem 0 3rem;
}

.cm-smellIntensity-modal .ant-radio-inner {
    border: none;
    box-shadow: 0 0 10px #3e3e3e9e;
    border-radius: 100%;
    height: 5rem;
    width: 5rem;
    appearance: none;
    position: relative;
    background-color: #fff;
}

.cm-smellIntensity-modal .ant-radio-inner:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25%;
    height: 25%;
    background-color: #da3832;
    border-radius: 100%;
    filter: blur(0.3rem);
    margin: 0;
    opacity: inherit;
}

.cm-smellIntensity-modal .ant-radio-checked .ant-radio-inner {
    background-color: #dfdfdf;
}

.cm-smellIntensity-modal .ant-radio>#medium+.ant-radio-inner:after {
    width: 40%;
    height: 40%;
    filter: blur(0.5rem);
}

.cm-smellIntensity-modal .ant-radio>#strong+.ant-radio-inner:after {
    width: 50%;
    height: 50%;
    filter: blur(0.5rem);
}

.cm-smellIntensity-modal .ant-radio>#intense+.ant-radio-inner:after {
    width: 75%;
    height: 75%;
    filter: blur(0.5rem);
}

.cm-smellIntensity-modal .ant-modal-footer {
    display: none;
}

.smell_wrapper.active {
    visibility: visible;
    opacity: 1;
}

@media (min-width: 1601px) {
    .scent_items .scent_item {
        width: 15.5rem;
        height: 15.5rem;
    }
}

@media (max-width: 1440px) {
    .cm-smellIntensity-modal .popup_head h3 {
        font-size: 2.5rem;
    }
}

@media (max-width: 1200px) {
    .scent_items .scent_item {
        width: 12.5rem;
        height: 12.5rem;
    }
}

@media (max-width: 1200px) {
    /* .howto_wrapper .howto_items .howto_item img {
        max-height: 6rem;
    } */
    .howto_wrapper .howto_items .howto_item h6 {
        font-size: 1.6rem;
        margin: 1rem 0 0;
    }
}


/*scent test page css end*/


/*result page css start*/

.qr_wrapper {
    height: 32rem;
    max-width: 32rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0.1rem solid #7d7d7d;
    border-radius: 1rem;
    width: 100%;
    margin: auto;
}

.qr_wrapper .qr_code {
    max-width: 50%;
    width: 100%;
    margin: 1rem 0;
}

.qr_wrapper h5 {
    font-size: 1.8rem;
    text-transform: uppercase;
    font-weight: 700;
}

.qr_wrapper .fail {
    position: relative;
    border: 0.2rem solid #94989b;
    border-radius: 100%;
    height: 5rem;
    width: 5rem;
    transition: all 0.5s ease-in-out;
    display: inline-block;
}

.qr_wrapper .fail::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    height: 70%;
    width: 0.4rem;
    background-color: red;
    transition: all 0.5s ease-in-out;
}

.qr_wrapper .fail::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    height: 70%;
    width: 0.4rem;
    background-color: red;
    transition: all 0.5s ease-in-out;
}

.qr_wrapper .pass {
    position: relative;
    border: 0.2rem solid #94989b;
    border-radius: 100%;
    height: 5rem;
    width: 5rem;
    transition: all 0.5s ease-in-out;
    display: inline-block;
}

.qr_wrapper .pass::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-70%, -50%) rotate(45deg);
    height: 70%;
    width: 0.4rem;
    background-color: green;
    transition: all 0.5s ease-in-out;
}

.qr_wrapper .pass::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-70%, -30%) rotate(-45deg);
    height: 40%;
    width: 0.4rem;
    background-color: green;
    transition: all 0.5s ease-in-out;
}


/* .result_wrapper {
    margin: 1.5rem 0;
    max-height: 30vh;
    overflow: auto;
    text-align: center;
}

    .result_wrapper h3 {
        line-height: 1.5;
        margin: 1.5rem 0;
    }

    .result_wrapper::-webkit-scrollbar {
        width: 0.5rem;
    }

    .result_wrapper::-webkit-scrollbar-thumb {
        background-color: darkgrey;
    } */

.bg_wrapper {
    background-color: #da3832;
    padding: 1.5rem 0;
}

.result_wrapper {
    text-align: center;
    background-color: #ffffff;
    padding: 3rem 0 0;
    width: 90%;
    margin: 1.5rem auto;
    box-shadow: 0 0 5px 5px #333333;
    position: relative;
}

.result_wrapper::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: -1.5rem auto 0;
    height: 3.5rem;
    width: 6rem;
    background-color: #da3832;
    border-radius: 0 0 4.5rem 4.5rem;
    box-shadow: inset 0px -5px 5px 2px #d7d7d7;
    border-top: 0.5rem solid #da3832;
}

.result_wrapper .result_logo {
    max-width: 15rem;
    margin-bottom: 10px;
}

.result_wrapper .result_desc {
    padding: 1.5rem 1rem;
    font-size: 1.4rem;
    line-height: 1;  
}

.result_wrapper .bg_light {
    background-color: #e2e2e2;
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
    padding: 0.5rem 0;
}

.result_wrapper .bg_dark {
    background-color: #000000;
    margin-bottom: 0.5rem;
    padding: 0.5rem 0;
    color: #ffffff;
}

.result_wrapper .bg_dark h4 {
    color: #ffffff;
    font-size: 1.5rem;
}

.result_wrapper .result_fail p {
    margin-bottom: 0;
    color: #ff0000;
    font-size: 5rem;
    text-transform: uppercase;
    font-weight: 600;
    margin-left: 1rem;
}

.result_wrapper .result_pass p {
    margin-bottom: 0;
    color: green;
    font-size: 5rem;
    text-transform: uppercase;
    font-weight: 600;
    margin-left: 1rem;
}

.result_wrapper::-webkit-scrollbar-thumb {
    background-color: darkgrey;
}

.result_wrapper .fail {
    position: relative;
    background-color: #ffffff;
    border: 0.2rem solid #94989b;
    border-radius: 100%;
    height: 5rem;
    width: 5rem;
    transition: all 0.5s ease-in-out;
    display: inline-block;
}

.result_wrapper .fail::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    height: 70%;
    width: 0.4rem;
    background-color: red;
    transition: all 0.5s ease-in-out;
}

.result_wrapper .fail::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    height: 70%;
    width: 0.4rem;
    background-color: red;
    transition: all 0.5s ease-in-out;
}

.result_wrapper .pass {
    position: relative;
    background-color: #ffffff;
    border: 0.2rem solid #94989b;
    border-radius: 100%;
    height: 5rem;
    width: 5rem;
    transition: all 0.5s ease-in-out;
    display: inline-block;
}

.result_wrapper .pass::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-70%, -50%) rotate(45deg);
    height: 70%;
    width: 0.4rem;
    background-color: green;
    transition: all 0.5s ease-in-out;
}

.result_wrapper .pass::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-70%, -30%) rotate(-45deg);
    height: 40%;
    width: 0.4rem;
    background-color: green;
    transition: all 0.5s ease-in-out;
}

.actions_wrapper {
    width: 90%;
    margin: auto;
}

.actions_wrapper .qr_code {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.actions_wrapper .action_btns {
    text-align: center;
    padding: 1.5rem 5px;
}

.actions_wrapper .action_btns .settings-btn,
.actions_wrapper .action_btns .next-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: auto;
    background-color: #000;
    padding: 1rem;
    border-radius: 0.5rem;
    height: 29.33%;
}

.actions_wrapper .action_btns .settings-btn span,
.actions_wrapper .action_btns .next-btn span {
    color: #ffffff;
    font-size: 1rem;
    display: inline-block;
    width: 80%;
}

.actions_wrapper .action_btns .settings-btn span b,
.actions_wrapper .action_btns .next-btn span b {
    font-weight: 700;
    display: inline-block;
}

.actions_wrapper .action_btns .next-btn {
    margin-top: 1rem;
}

.actions_wrapper .action_btns .settings-btn img,
.actions_wrapper .action_btns .next-btn img {
    max-width: 2.5rem;
}

.actions_wrapper .result_desc {
    padding: 1.5rem 1rem;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.25;
    text-align: center;
    border-top: 0.1rem solid #000000;
}

.actions_wrapper .action_btns.left a {
    /* height: Calc(50% - 1.25rem); */
}


/*result page css end*/


/*startup page css start*/

.scan_wrapper {
    padding: 4.5rem 1.5rem;
    background-color: #da3832;
}

.scan_wrapper .tap_wrapper {
    height: 20rem;
    max-width: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0.1rem solid #7d7d7d;
    border-radius: 100%;
    width: 100%;
    margin: auto;
    padding: 2rem;
    line-height: 1;
    background-color: #333;
}

.scan_wrapper .tap_wrapper p {
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    font-weight: 600;
    color: #ffffff;
}


.scan_wrapper .tap_wrapper .scaner video {
    object-fit: cover;
}

.scan_wrapper .extra_links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 60%;
    margin: 0 auto -2rem;
}

.scan_wrapper .extra_links a {
    background-color: #333333;
    padding: 1rem;
    border-radius: 100%;
}

.scan_wrapper .extra_links a img {
    max-width: 2.5rem;
}


/*start page css end*/


/*splash page css start*/

.ant-form-item-explain-error {
    color: #ff0000;
    font-size: 1.5rem;
}

.splash_wrapper {
    min-height: Calc(100vh - 11rem);
}

.splash_wrapper .bg-dark {
    text-align: center;
    /* width: 70%;
    margin: 0 auto; */
    padding: 7rem 1.5rem 0;
    background-color: #333333 !important;
}

.splash_wrapper .splash_logo {
    max-width: 18rem;
}

.splash_wrapper .tagline {
    margin: 1rem auto 0;
    padding-top: 1rem;
    border-top: 0.2rem solid #ffffff;
    width: 50%;
    line-height: 1.2;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 500;
    color: #ffffff;
}

.splash_wrapper form {
    margin-top: 1.5rem;
}

.splash_wrapper form .form-group {
    text-align: left;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;
    position: relative;
}

.splash_wrapper form .form-group.name {
    margin-bottom: 1rem;
    max-height: 8.7rem;
}

.splash_wrapper form .form-group .ant-checkbox-wrapper {
    margin-right: 1rem;
}

.splash_wrapper form .form-group .ant-typography {
    color: #ffffff;
}

.splash_wrapper form .form-group .ant-form-item {
    margin-bottom: 0;
}

/* .splash_wrapper form .form-group .ant-form-item-with-help .ant-form-item-explain {
    position: absolute;
    top: 100%;
    left: 0;
} */


/* .splash_wrapper form .action-btn {
    background-color: #333333;
} */

.splash_wrapper form .action-btn .btn {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.splash_wrapper form .form-group label {
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 0;
    line-height: 1;
    height: auto;
}

.splash_wrapper form .form-group .text-sm label {
    font-size: 10px;
    text-transform: none;
}

.splash_wrapper form .form-group label.ant-form-item-required::before {
    position: absolute;
    top: 40%;
    right: -1.7rem;
    transform: translateY(-50%);
    color: #db3833;
    margin-left: 4px;
}

.splash_wrapper form .form-group input {
    border: 0.1rem solid #7d7d7d;
    border-radius: 0.5rem;
    padding: 1rem;
    width: 100%;
    font-size: 1.5rem;
    color: #000000;
}

.splash_wrapper form .form-group .form-control {
    background-color: #ffffff;
    border: none;
    height: 4rem;
    padding: 1rem;
    color: #333333;
    border-radius: 0;
}

.splash_wrapper form .form-group .form-control:focus {
    box-shadow: none;
}

.splash_wrapper form .action-btn {
    background-color: #a8a8a8;
    margin: 0 -1.5rem;
    filter: drop-shadow(0px 0px 4px #000000ad);
}

.splash_wrapper form .action-btn .ant-form-item {
    margin-bottom: 0;
}

.splash_wrapper form .btn {
    width: 100%;
    border-radius: 2.5rem;
    background-color: #da3832;
    color: #fff;
    padding: 1rem 1.5rem;
    height: auto;
    border: none;
    font-size: 2.5rem;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.1rem;
    margin: 1.5rem 0;
}

.splash_wrapper form .btn-primary:not(:disabled):not(.disabled):active {
    background-color: #da3832;
}

.splash_wrapper form .btn:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
}

footer {
    text-align: center;
    position: sticky;
    bottom: 0 !important;
    left: 0;
    right: 0;
    margin: 0 auto !important;
    height: auto !important;
    padding: 1rem 1.5rem;
    width: 100%;
}

footer p {
    margin-bottom: 0;
    color: #333333;
}

footer p:nth-of-type(2) {
    margin-top: 1rem;
}

footer.home_footer {
    background-color: #ffffff;
    position: relative;
}

@supports (-webkit-touch-callout: none) {
    footer {
        position: sticky;
    }
}

@media (min-width: 1600px) {
    footer {
        font-size: 1.2rem;
    }
}

@media (max-width: 1280px) and (max-height: 725px) {
    .howto_wrapper .howto_items .howto_item img {
        max-height: 5rem;
    }
    .scan_wrapper .tap_wrapper {
        height: 15rem;
        max-width: 15rem;
    }
    .splash_wrapper .bg-dark {
        padding: 5rem 1.5rem 0;
    }
}

@media (max-width: 575px) and (max-height: 823px) {
    /* main {
        overflow: hidden;
    }
    .front-end-root {
        overflow: hidden;
    } */
    .cm-container {
        margin: 0;
        /* overflow: hidden; */
    }
    .scent_items {
        padding: 2.8rem 0;
    }
    .scent_items>.row .col-12 {
        margin-top: -2rem;
        margin-bottom: -2rem;
    }
    .scent_items .scent_item {
        width: 10rem;
        height: 10rem;
    }
    .scent_items .scent_item p {
        font-size: 1.2rem;
    }
    .howto_wrapper .howto_items .howto_item img {
        max-height: 5rem;
    }
    .howto_wrapper .howto_items .howto_item h6 {
        font-size: 1.4rem;
        margin: 1rem 0 0;
    }
    .steps_wrapper .steps {
        margin: 1.5rem auto;
    }
    .steps_wrapper .steps .step {
        margin: 0.5rem 1rem;
    }
}


/*scent test page css end*/


/*result page css start*/

.qr_wrapper {
    height: 32rem;
    max-width: 32rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0.1rem solid #7d7d7d;
    border-radius: 1rem;
    width: 100%;
    margin: auto;
}

.qr_wrapper .qr_code {
    max-width: 50%;
    width: 100%;
    margin: 1rem 0;
}

.qr_wrapper h5 {
    font-size: 1.8rem;
    text-transform: uppercase;
    font-weight: 700;
}

.qr_wrapper .fail {
    position: relative;
    border: 0.2rem solid #94989b;
    border-radius: 100%;
    height: 5rem;
    width: 5rem;
    transition: all 0.5s ease-in-out;
    display: inline-block;
}

.qr_wrapper .fail::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    height: 70%;
    width: 0.4rem;
    background-color: red;
    transition: all 0.5s ease-in-out;
}

.qr_wrapper .fail::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    height: 70%;
    width: 0.4rem;
    background-color: red;
    transition: all 0.5s ease-in-out;
}

.qr_wrapper .pass {
    position: relative;
    border: 0.2rem solid #94989b;
    border-radius: 100%;
    height: 5rem;
    width: 5rem;
    transition: all 0.5s ease-in-out;
    display: inline-block;
}

.qr_wrapper .pass::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-70%, -50%) rotate(45deg);
    height: 70%;
    width: 0.4rem;
    background-color: green;
    transition: all 0.5s ease-in-out;
}

.qr_wrapper .pass::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-70%, -30%) rotate(-45deg);
    height: 40%;
    width: 0.4rem;
    background-color: green;
    transition: all 0.5s ease-in-out;
}


/* .result_wrapper {
    margin: 1.5rem 0;
    max-height: 30vh;
    overflow: auto;
    text-align: center;
}

    .result_wrapper h3 {
        line-height: 1.5;
        margin: 1.5rem 0;
    }

    .result_wrapper::-webkit-scrollbar {
        width: 0.5rem;
    }

    .result_wrapper::-webkit-scrollbar-thumb {
        background-color: darkgrey;
    } */

.result_wrapper {
    text-align: center;
    /* text-align: left; */
    background-color: #ffffff;
    padding: 1.5rem 0 0;
    width: 90%;
    margin: 0 5%;
    /* box-shadow: 0 0 5px 5px rgba(51, 51, 51, 0.50); */
    box-shadow: none;
    position: relative;
}

.result_wrapper::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: -1.5rem auto 0;
    height: 3.5rem;
    width: 6rem;
    background-color: #da3832;
    border-radius: 0 0 4.5rem 4.5rem;
    box-shadow: inset 0px -5px 5px 2px rgba(51, 51, 51, 0.50);
    border-top: 1rem solid #da3832;
    display: none;
}

.result_wrapper .result_logo {
    max-width: 12rem;
    /* max-width: 30rem; */
}

.result_wrapper .result_desc {
    padding: 1.5rem 3.5rem;
    font-size: 1.5rem; /*1.6rem*/
    line-height: 1;
    margin-bottom: 0;
    text-align: left;
    font-weight: 500;
}

.result_wrapper .result_desc .disclaimer {
    color: #014faa;
    margin-bottom: 0;
}

.result_wrapper .bg_light {
    background-color: #e2e2e2;
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
    padding: 0.5rem 0;
}

.result_wrapper .bg_dark {
    background-color: #000000;
    margin-bottom: 0.5rem;
    padding: 0.5rem 0;
    color: #ffffff;
}

.result_wrapper .bg_dark h4 {
    color: #ffffff;
    font-size: 1.5rem;
}

.result_wrapper .result_fail p {
    margin-bottom: 0;
    color: #da3832;
    font-size: 5rem;
    text-transform: uppercase;
    font-weight: 600;
    margin-left: 1rem;
}

.result_wrapper .result_pass p {
    margin-bottom: 0;
    color: green;
    font-size: 5rem;
    text-transform: uppercase;
    font-weight: 600;
    margin-left: 1rem;
}

.result_wrapper::-webkit-scrollbar-thumb {
    background-color: darkgrey;
}

.result_wrapper .fail {
    position: relative;
    background-color: #ffffff;
    border: 0.2rem solid #94989b;
    border-radius: 100%;
    height: 5rem;
    width: 5rem;
    transition: all 0.5s ease-in-out;
    display: inline-block;
}

.result_wrapper .fail::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    height: 70%;
    width: 0.4rem;
    background-color: #da3832;
    transition: all 0.5s ease-in-out;
}

.result_wrapper .fail::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    height: 70%;
    width: 0.4rem;
    background-color: #da3832;
    transition: all 0.5s ease-in-out;
}

.result_wrapper .pass {
    position: relative;
    background-color: #ffffff;
    border: 0.2rem solid #94989b;
    border-radius: 100%;
    height: 5rem;
    width: 5rem;
    transition: all 0.5s ease-in-out;
    display: inline-block;
}

.result_wrapper .pass::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-70%, -50%) rotate(45deg);
    height: 70%;
    width: 0.6rem;
    background-color: green;
    transition: all 0.5s ease-in-out;
}

.result_wrapper .pass::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-70%, -30%) rotate(-45deg);
    height: 40%;
    width: 0.6rem;
    background-color: green;
    transition: all 0.5s ease-in-out;
}

.result_wrapper .result-content {
    list-style: none;
    padding: 0 3.5rem;
    margin: 0;
    text-align: left;
}

.result_wrapper .result-content li {
    margin-bottom: 0.5rem;
    font-weight: 700;
    color: #333333;
    font-size: 1.5rem;
    line-height: 1.25;
}

.result_wrapper .result-content li u {
    width: 60%;
    display: inline-block;
    vertical-align: text-top;
}

.result_wrapper .result-content label {
    padding-right: 0.5rem;
    margin-bottom: 0;
    text-decoration: underline;
    white-space: nowrap;
}

.result_wrapper .result-ttl {    
    text-align: center;  
    font-weight: 700;
    color: #333333;
    margin: 2rem 0;
}

.result_wrapper .patients-details {    
    list-style: none;
    padding: 0 1rem;
    margin: 0 0 2rem;
    text-align: left;
}

.result_wrapper .patients-details li {
    padding-right: 1rem;
    margin-bottom: 0.5rem;
    line-height: 1.15;
}

.result_wrapper .patients-details label {
    padding-right: 0.5rem;
    margin-bottom: 0;
    text-decoration: underline;
}

.actions_wrapper {
    width: 90%;
    margin: auto;
}

.actions_wrapper .qr_code {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.actions_wrapper .action_btns {
    text-align: center;
    padding: 1.5rem 5px;
}

.actions_wrapper .action_btns .settings-btn,
.actions_wrapper .action_btns .next-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: auto;
    background-color: #000000;
    padding: 1rem;
    border-radius: 0.5rem;
    height: 29.33%;
}

.actions_wrapper .action_btns .settings-btn span,
.actions_wrapper .action_btns .next-btn span {
    color: #ffffff;
    font-size: 1rem;
    display: inline-block;
    width: 80%;
}

.actions_wrapper .action_btns .settings-btn span b,
.actions_wrapper .action_btns .next-btn span b {
    font-weight: 700;
    display: inline-block;
}

.actions_wrapper .action_btns .next-btn {
    margin-top: 1rem;
}

.actions_wrapper .action_btns .next-btn:first-child {
    margin-top: 0;
}

.actions_wrapper .action_btns .settings-btn img,
.actions_wrapper .action_btns .next-btn img {
    max-width: 2.5rem;
}

.actions_wrapper .result_desc {
    padding: 1.5rem 1rem;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.25;
    text-align: center;
    border-top: 0.1rem solid #000000;
    margin-bottom: 2rem;
}

.actions_wrapper .action_btns.left a {
    /* height: Calc(50% - 1.25rem); */
}

/*result page css end*/


/*startup page css start*/

.scan_code_page,
.scent_page .scent_body,
.result_page .result_body,
.questions_page .questions_wrapper,
.questions_page .questions_body,
.additional_result_wrapper .additional_result_body {
    min-height: calc(100vh - 11.2rem);
}

.scent_page footer,
.result_page footer,
.questions_page footer,
.additional_result_wrapper footer {
    background-color: #ffffff;
}


/* .scan_wrapper {
    margin: 8.5rem 0 10rem;
} */

.scan_wrapper .tap_wrapper {
    height: 20rem;
    max-width: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 100%;
    width: 100%;
    margin: auto;
    padding: 3.5rem 3rem 3rem;
    line-height: 1;
    background-color: #a8a8a8;
    border: 0.2rem solid #333333;
}

.scan_wrapper .tap_wrapper p {
    margin-bottom: 0.8rem;
    text-transform: uppercase;
    font-weight: 600;
    color: #ffffff;
    font-size: 1.2rem;
    text-align: center;
}

.scan_wrapper .tap_wrapper .scaner {
    max-width: 70%;
    width: 100%;
    height: auto;
    object-fit: contain;
}


 .scan_wrapper .tap_wrapper .QRCodeScanner {
        max-width: 100%;
}
.scan_wrapper .extra_links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 60%;
    margin: 0 auto -2rem;
}

.scan_wrapper .extra_links a {
    background-color: #333333;
    padding: 1rem;
    border-radius: 100%;
    line-height: 0;
}

.scan_wrapper .extra_links a img {
    max-width: 2.5rem;
}


/*start page css end*/


/*splash page css start*/

.ant-form-item-explain-error {
    color: #ff0000;
    font-size: 1.5rem;
}


/* .splash_wrapper {
    text-align: center;
    width: 70%;
    margin: 7rem auto;
}

    .splash_wrapper .splash_logo {
        max-width: 18rem;
    }

    .splash_wrapper .tagline {
        margin: 1rem auto 0;
        padding-top: 1rem;
        border-top: 0.2rem solid #ffffff;
        width: 70%;
        line-height: 1.2;
        text-transform: uppercase;
    }

    .splash_wrapper form {
        margin-top: 2.5rem;
    }

        .splash_wrapper form .form-group {
            text-align: left;
        }

            .splash_wrapper form .form-group label {
                text-transform: uppercase;
                color: #000000;
                font-weight: 500;
                margin-bottom: 0.5rem;
            }

            .splash_wrapper form .form-group input {
                border: 0.1rem solid #7d7d7d;
                border-radius: 0.5rem;
                padding: 1rem;
                width: 100%;
                font-size: 1.5rem;
                color: #000000;
            }

            .splash_wrapper form .form-group .form-control {
                background-image: linear-gradient(to right, #c4c4c4, #e2e2e2);
                border: none;
                height: 3.5rem;
                padding: 1rem;
                color: #4a4a4a;
            }

                .splash_wrapper form .form-group .form-control:focus {
                    box-shadow: none;
                }

        .splash_wrapper form .btn {
            width: 100%;
            border-radius: 2.5rem;
            background-color: #ff0000;
            color: #fff;
            padding: 1rem 1.5rem;
            height: auto;
            border: none;
            font-size: 2.5rem;
            text-transform: uppercase;
            font-weight: 400;
            letter-spacing: 0.1rem;
            margin-top: 1.5rem;
        }

        .splash_wrapper form .btn-primary:not(:disabled):not(.disabled):active {
            background-color: #ff0000;
        }

        .splash_wrapper form .btn:focus,
        .btn-primary:not(:disabled):not(.disabled):active:focus {
            box-shadow: none;
        } */


/* footer {
    text-align: center;
    position: sticky;
    bottom: -2rem;
    left: 0;
    right: 0;
    margin: 1rem auto;
}

    footer p {
        margin-bottom: 0;
    } */

@media (min-width: 1600px) {
    footer {
        font-size: 1.2rem;
    }
}


/*splash page css end*/

.pdf-download-img {
    max-width: 2.5rem;
}

.cmLoader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
}

.cm-agree {
    display: flex;
    align-items: center;
}

.cm-agree .ant-checkbox {
    margin-right: 1rem;
}

.cm-agree .termButton {
    font-size: 1.4rem;
    line-height: 1.25;
    height: auto;
}

.scent-card-option-popup .ant-modal-body {
    padding-bottom: 1px;
}

.scent-card-option-popup .action-footer .ant-form-item-control {
    display: flex;
    align-items: flex-end;
}


/*questions section css start*/

.questions_wrapper h1 {
    text-align: center;
    padding: 1.5rem;
    margin: 0;
    font-weight: 600;
}

.questions_wrapper h4 {
    padding: 1.5rem;
    margin: 0;
    font-weight: 600;
    font-size: 2.4rem;
}

.questions_wrapper .action_btns {
    width: 100%;
    margin: 2.5rem 0 0;
}

.questions_wrapper .action_btns .ant-radio-button-wrapper {
    margin: 1rem auto 2.5rem;
    width: 50%;
    background-color: #333;
    display: block;
    padding: 1.5rem;
    height: auto;
    color: #fff;
    font-weight: 500;
    font-size: 1.6rem;
    text-align: center;
    border-radius: 1rem;
    box-shadow: 0 0 10px 0px #efefef;
    border: none !important;
}

.questions_wrapper .action_btns .ant-radio-button-wrapper:active {
    box-shadow: 0 0 10px 0px #999999;
    background-color: #db3833;
}

.questions_wrapper .action_btns .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
}

.progress_wrapper {
    margin: 2.5rem auto;
}

.progress_wrapper .ant-progress-circle .ant-progress-text {
    font-size: 4rem;
    font-weight: 500;
    color: #333;
}

.progress_wrapper .ant-progress-circle-trail {
    stroke: rgba(219, 56, 51, 0.20);
}

.progress_wrapper .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: #db3833;
}


/*questions section css end*/


/*additional result section css start*/

.additional_result_wrapper {
    padding: 0 1.5rem;
}

.additional_result_wrapper header {
    margin: 0 -1.5rem;
}

.additional_result_wrapper .progress_wrapper {
    padding: 5rem 0;
    margin: auto;
}

.additional_result_wrapper .progress_wrapper .ant-progress-circle-trail {
    fill: #ffffff;
    fill-opacity: 1 !important;
}

.additional_result_wrapper .progress_wrapper .ant-progress-status-success .ant-progress-inner {
    box-shadow: 0 0 10px #a8a8a8;
}

.additional_result_wrapper .progress_wrapper .ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: rgb(82, 196, 26);
}

.additional_result_wrapper .progress_wrapper .anticon-check svg {
    fill: rgb(82, 196, 26);
}

.btn-ctnu {
    background-color: #333333;
    color: #fff;
    border: none;
    padding: 1.5rem 2rem;
    font-size: 1.6rem;
    font-weight: 500;
    border-radius: 1rem;
    box-shadow: 0 0 10px 0px #999999;
    margin: auto;
    display: table;
}

.btn-ctnu:hover {
    background-color: #db3833;
}

.ctnu-text {
    padding: 1.5rem;
    margin: 0;
    font-weight: 600;
    font-size: 2.4rem;
}


/*additional result section css end*/


/*add organization page css start*/

.add_organization_page {
    background-color: #db3833;
}

.add_organization_wrapper {
    min-height: Calc(100vh - 11.1rem);
}

.add_organization_wrapper .profile_wrapper {
    padding: 1.5rem;
}

.add_organization_wrapper .profile_wrapper .profile_ttl {
    color: #fff;
    font-size: 2.5rem;
    margin: 1rem 0;
    font-weight: 600;
}

.add_organization_wrapper .profile_wrapper .profile_itm {
    text-align: center;
}

.add_organization_wrapper .profile_wrapper .profile_itm .user_icn {
    max-width: 7rem;
    margin-bottom: 1rem;
    background-color: #333333;
    border-radius: 100%;
}

.add_organization_wrapper .profile_wrapper .profile_itm .user_name {
    text-transform: capitalize;
    font-weight: 700;
}

.add_organization_wrapper .select_organization_wrapper {
    padding: 1.5rem;
    text-align: center;
}

.add_organization_wrapper .select_organization_wrapper .nxt-btn {
    margin: 1.5rem auto;
    background: #000;
    color: #fff;
    border: none;
    padding: 1.5rem 2rem;
    font-size: 1.6rem;
    font-weight: 500;
    border-radius: 1rem;
    box-shadow: 0 0 5px 0px #000000;
}

.add_organization_wrapper .select_organization_wrapper h2 {
    color: #ffffff;
    font-size: 2rem;
    font-weight: 600;
}

.add_organization_wrapper .select_organization_wrapper p {
    color: #ffffff;
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.add_organization_wrapper .select_organization_wrapper .ant-select {
    width: 100%;
    border: none;
    font-size: 1.6rem;
    font-weight: 500;
    height: 3.4rem;
    margin-bottom: 1rem;
    text-align: left;
}

.add_organization_wrapper .select_organization_wrapper .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    right: auto;
    left: 3.2rem;
}

.add_organization_wrapper .select_organization_wrapper .ant-select .ant-select-selector {
    padding-left: 4rem;
    height: 3.4rem;
    border: none;
}

.add_organization_wrapper .select_organization_wrapper .ant-select .ant-select-selection-search-input {
    width: 100%;
    border: none;
    border-radius: 1rem;
    color: #000000;
    font-size: 1.6rem;
    padding: 1rem;
    height: 3.4rem;
}

.add_organization_wrapper .select_organization_wrapper .ant-select .ant-select-arrow {
    right: auto;
    left: 0;
    background-color: #000;
    color: #fff;
    height: 3.4rem;
    margin-top: 0;
    top: 0;
    padding: 0.7rem;
}

.add_organization_wrapper .select_organization_wrapper .ant-select .ant-select-arrow svg {
    height: 2rem;
    width: 2rem;
}

.add_organization_wrapper .select_organization_wrapper .ant-select .ant-select-selection-item,
.add_organization_wrapper .select_organization_wrapper .ant-select .ant-select-selection-placeholder {
    padding-right: 0;
    line-height: 2;
}

.add_organization_page footer {
    background-color: #db3833;
}

.add_organization_page footer p {
    color: #ffffff;
}

/*add organization page css end*/

.cm-btn-question-email{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 1rem 0 2rem;
    background-color: #000000d9;
    padding: 1rem;
    border-radius: 0.5rem;
    color: #fff;
    font-size: 1.4rem;
    height: 29.33%;
}

.cm-btn-question-email:hover,
.cm-btn-question-PDF:hover{
    color: #fff;
}

.cm-btn-question-PDF{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 1rem;
    background-color: #000000d9;
    padding: 1rem;
    border-radius: 0.5rem;
    color: #fff;
    font-size: 1.4rem;
    height: 29.33%;
}

#map {
    height: 500px;
}

/*User Medical Info css start*/
.usermedical_wrapper .ant-col-xs-10 {
    display: block;
    flex: 0 0 41.66666667% !important;
    max-width: 41.66666667% !important;
    padding: 0;
}

.usermedical_wrapper .ant-col-xs-14 {
    display: block;
    flex: 0 0 58.33333333% !important;
    max-width: 58.33333333% !important;
}

.usermedical_wrapper .w-100 .ant-col-xs-14 {
    display: block;
    flex: 0 0 100% !important;
    max-width: 100% !important;
}

.usermedical_wrapper h3, .usermedical_wrapper .ant-form-item-label > label {
    color: #ffffff;
    font-weight: 400;
    font-size: 1.4rem;
}

.usermedical_wrapper h3 {
    font-size: 1.8rem;
    font-weight: 700;
}

.usermedical_wrapper .ant-form-item-label > label {
    padding-right: 1.4rem;
}

.usermedical_wrapper .ant-form-item {
    margin-bottom: 1.6rem;
}

.usermedical_wrapper label span {
    color: #ffffff;
    font-weight: 600;
    font-size: 1.4rem;
}

.usermedical_wrapper .ant-btn-primary {
    background-color: #000000;
    color: #ffffff;
    font-size: 1.6rem;
    font-weight: 600;
    border: none;
    padding: 1rem 3rem;
    border-radius: 0.8rem;
    height: auto;
    margin-top: 1.6rem;
    line-height: 1.5;
    letter-spacing: 0.2rem;
    text-transform: uppercase;
}

.usermedical_wrapper .ant-radio-group {
    display: flex;
    align-items: center;
    padding-left: 2.5rem;
}

.usermedical_wrapper .ant-radio-group .ant-radio-wrapper {
    width: 50%;
}

.usermedical_wrapper .ant-form-item-explain-error {
    color: #ffffff;
    text-align: left;
    font-weight: 600;
    font-size: 1.4rem;
}

.ant-select-dropdown .ant-select-item-option-content {
    white-space: unset;
}

.usermedical_wrapper {
    min-height: Calc(100vh - 16.2rem);
}

.usermedical_wrapper form {
    position: relative;
    min-height: Calc((100vh - 15.2rem) - 18.8rem);
    margin-bottom: 7rem;
}

.usermedical_wrapper .usermedical_slider {
    min-height: Calc((100vh - 15.2rem) - 19.2rem);
}

.usermedical_wrapper .usermedical_slider .slick-list .slick-slide {
    margin-bottom: 2rem;
}

.usermedical_wrapper .usermedical_slider .slick-dots {
    margin-bottom: 0;
    bottom: 0;
    align-items: center;
}

.usermedical_wrapper .usermedical_slider .slick-dots li {
    display: block;
    width: auto;
    height: auto;
}

.usermedical_wrapper .usermedical_slider .slick-dots li button {
    width: 16px;
    height: 16px;
    border-radius: 100%;
}

.usermedical_wrapper .usermedical_slider .slick-dots li button:hover, .usermedical_wrapper .usermedical_slider .slick-dots li button:focus {
    opacity: 0.3;
}

.usermedical_wrapper .usermedical_slider .slick-dots li.slick-active button:hover, .usermedical_wrapper .usermedical_slider .slick-dots li.slick-active button:focus {
    opacity: 1;
}

.usermedical_wrapper .usermedical_slider .slick-dots li.slick-active button::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 22px;
    height: 22px;
    border: 1px solid #ffffff;
    border-radius: 100%;
    transform: translate(-50%, -50%);
}

.usermedical_wrapper .usermedical_slider .slick-arrow {
    top: auto;
    bottom: 0;
    width: auto;
    height: auto;
    line-height: 0 !important;
    z-index: 16;
    color: #ffffff;
}

.usermedical_wrapper .usermedical_slider .slick-arrow.slick-next {
    right: Calc(50% - 75px);
    transform: translateX(-50%);
}

.usermedical_wrapper .usermedical_slider .slick-arrow.slick-prev {
    left: Calc(50% - 55px);
    transform: translateX(-50%);
}

.usermedical_wrapper .usermedical_slider .slick-arrow::before {
    display: none;
}

.usermedical_wrapper .action-btn {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 0;
}

@media (max-width: 575px) {
    .custom_label {
        line-height: 1.5715;
        white-space: initial;
        text-align: left;
    }
}
/*User Medical Info css end*/

/*scrollable modal css start*/
.ant-modal.scrollable {
    height: 80vh;
    overflow: hidden;
}

.ant-modal.scrollable .ant-modal-content {
    height: 80vh;
    overflow: auto;
}
/*scrollable modal css end*/
