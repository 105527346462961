#overlay {
  background: #ffffff61;
  color: #06d5fe;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 10050;
  top: 0;
  left: 0;
  float: left;
  text-align: center;
  padding-top: 25%;
  opacity: 0.8;
}
.cm-component-loader {
  background: #ffffff61;
  color: #06d5fe;
  opacity: 0.8;
  z-index: 2;
  left: 0;
}
.cm-input-component-loader {
  right: 25px;
  top: 35px;
  z-index: 2;
  color: #06d5fe;
}
.cm-loader-25 {
  height: 25px;
  width: 25px;
}
