.fix-footer {
    position: fixed !important;
    height: 28px !important;
    bottom: 0 !important;
    width: 100%;
    margin: 0 !important;
    border-top: 2px solid #dee2e6 !important;
    box-shadow: 0 0.1rem 1.75rem rgb(0 0 0 / 5%);
}

.admin-root {
    background-color: #ffffff;
    min-height: 85vh;
}

.admin-footer {
    position: unset !important;
    bottom: 0 !important;
    padding: 1rem 0;
    background-color: #ffffff;
}

.custom-card-width {
    max-width: 18rem;
}

.logo-admin {
    height: 45px;
    width: auto;
}

.page-header h1 {
    border-bottom: 1px solid #d7d5d5;
    font-size: 20px;
    padding: 10px 0 15px;
    margin-bottom: 15px;
}

.manage-test-kit .ant-tabs-nav {
    padding-top: 15px;
}

.manage-test-kit .ant-tabs-content-holder {
    padding-top: 20px;
}

.manage-test-kit .test-kit-qr-code {
    padding: 20px 0px;
}

.manage-test-kit .test-kit-qr-code .qr-code-label {
    border-bottom: 1px solid #d7d5d5;
    padding: 10px 0;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
}

.manage-test-kit .test-kit-qr-code .test-qr-code {
    border: 1px solid #d7d5d5;
    border-radius: 5px;
    margin: 10px 0;
    height: 202px;
    width: 202px;
}

.manage-test-kit .test-kit-qr-code .qr-code-not-available img {
    opacity: 0.2;
    padding: 10px;
}

.download-qr-code {
    width: 200px !important;
}

.cm-add-scent .ant-form-item-label {
    display: block;
}


/* test result css start */

.cm-filter-wrapper {
    margin-bottom: 1.5rem;
}

.cm-filter-wrapper .cm-search .ant-form-item {
    display: block;
    max-width: 87%;
    width: 100%;
}

.cm-filter-wrapper .cm-country-form .ant-form-item {
    margin-bottom: 0;
    padding: 0 1rem;
}

.cm-filter-wrapper .ant-form-item:first-child {
    padding-left: 0;
}

.cm-filter-wrapper .ant-form-item:last-child {
    padding-right: 0;
}

.cm-filter-wrapper .action_btn.ant-form-item {
    padding-left: 2rem;
}

.cm-filter-wrapper .ant-form-item .ant-input {
    width: 100%;
}

.cm-filter-wrapper .ant-form-item-label>label {
    margin-bottom: 1rem;
    height: auto;
}

.ant-select-dropdown {
    min-width: fit-content !important;
}

.cm-test-filer-wrapper.cm-filter-wrapper .ant-form-item:first-child {
    padding-left: 1rem;
}

.cm-test-filer-wrapper.cm-filter-wrapper .ant-form-item:last-child {
    padding-right: 1rem;
}

.cm-test-filer-wrapper.cm-filter-wrapper .ant-form-item .ant-slider {
    margin: 0 6px 0;
}

.cm-test-filer-wrapper.cm-filter-wrapper .ant-form-item .reset_btn {
    height: auto;
    line-height: 0;
    padding: 5px;
    width: 100%;
}

.cm-test-filer-wrapper.cm-filter-wrapper .cm-search {
    padding: 0 15px;
}

.cm-test-filer-wrapper.cm-filter-wrapper .cm-search .ant-row .ant-row {
    padding-left: 0;
}

/* test result css end */


/*add scent item css start*/

.cm-add-scent-wrapper .card {
    border-radius: 0.8rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.125);
}

.cm-add-scent-wrapper .card-header {
    padding: 15px;
    border-radius: 0;
}

.cm-add-scent-wrapper .card-header h4,
.cm-add-scent-wrapper .card-header .card-title {
    margin-bottom: 0;
}

.cm-add-scent-wrapper .cm-add-scent {
    padding-top: 24px;
}

.cm-add-scent-wrapper .cm-add-scent .ant-form .ant-form-item {
    display: block;
    padding: 0 0 0 10px;
}

.cm-add-scent-wrapper .cm-add-scent .ant-form .ant-form-item.col-12 {
    padding: 0 24px;
    text-align: right;
}

.cm-add-scent-wrapper .cm-add-scent .ant-form .ant-form-item .ant-form-item-label {
    text-align: left;
}

.cm-add-scent-wrapper .cm-add-scent .ant-form .ant-form-item .ant-form-item-label>label {
    height: auto;
}

.cm-add-scent-wrapper .cm-add-scent .ant-form .ant-divider-horizontal {
    margin: 0 0 24px 0;
}

.cm-add-scent-wrapper .cm-add-scent .ant-form .test-kit-qr-code {
    height: 100%;
    justify-content: space-between;
    padding: 0 10px 24px 0;
}

.cm-add-scent-wrapper .cm-add-scent .ant-form .test-kit-qr-code .test-qr-code {
    border: 1px solid #dee2e6;
    padding: 24px 0;
}

.cm-add-scent-wrapper .cm-add-scent .ant-form .test-kit-qr-code .test-qr-code img {
    object-fit: contain;
}

.cm-add-scent-wrapper .cm-add-scent .ant-form .ant-upload {
    padding: 10px;
}

@media (max-width: 767px) {
    .cm-add-scent-wrapper .cm-add-scent .ant-form .test-kit-qr-code {
        padding: 0 10px 24px;
    }
    .cm-add-scent-wrapper .cm-add-scent .ant-form .ant-form-item {
        padding: 0 10px;
    }
}


/*add scent item css end*/


/*change password css start*/

.cm-country-form .ant-form-item {
    display: block;
    margin-bottom: 2.4rem;
}

.cm-country-form .ant-form-item .ant-form-item-label>label {
    height: auto;
    margin-bottom: 1rem;
}


/*change password css end*/


/*Add Organization css start*/

.cm-add-organization-wrapper .ant-form-item {
    display: block;
    padding: 0 15px;
    margin-bottom: 2rem;
}

.cm-add-organization-wrapper .ant-form-item .ant-form-item-label>label {
    height: auto;
}

.cm-add-organization-wrapper .page_ttl {
    font-size: 1.6rem;
    margin-bottom: 2rem;
    line-height: normal;
}

.cm-add-organization-wrapper .organization_email {
    margin: 0.5rem 0 2rem;
    align-items: center;
    padding: 0 15px;
}

.cm-add-organization-wrapper .organization_email .ant-checkbox-wrapper {
    margin-right: 0.5rem;
}

.cm-add-organization-wrapper .organization_email p {
    font-size: 1rem;
    margin-bottom: 0;
}

.cm-add-organization-wrapper .email {
    position: relative;
}

.cm-add-organization-wrapper .email .remark {
    position: absolute;
    top: 0.2rem;
    left: 20%;
    font-size: 1.2rem;
    color: #000000;
}


/*Add Organization css end*/


/* search organization wrapper css start */

.search_organization_wrapper .date_picker_wrapper {
    display: flex;
    align-items: flex-end;
    transition: all 0.5s ease-in-out;
}

.search_organization_wrapper .ant-form-item {
    margin-bottom: 0;
}

.search_organization_wrapper .date_picker_wrapper .ant-form-item {
    display: block;
    padding: 0 15px;
    width: 100%;
}

.search_organization_wrapper .date_picker_wrapper .ant-form-item .ant-form-item-label {
    display: block;
    text-align: left;
}

.search_organization_wrapper .date_picker_wrapper .ant-form-item .ant-form-item-label>label {
    height: auto;
}

.search_organization_wrapper .date_picker_wrapper .ant-form-item .ant-picker {
    width: 100%;
}

.search_organization_wrapper .date_picker_wrapper .ant-form-item .ant-form-item-explain {
    position: absolute;
    top: 100%;
}


/* .search_organization_wrapper form {
    min-height: 12.2rem;
} */

.search_organization_wrapper form .ant-radio-wrapper {
    padding-bottom: 1rem;
    display: block;
}

.search_organization_wrapper form .ant-radio-wrapper:last-child {
    padding-bottom: 0;
}


/* search organization wrapper css end */


/*admin login page css start*/

.login_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.login_wrapper .logo_wrapper {
    margin-bottom: 24px;
}

.login_wrapper .logo_wrapper .logo {
    max-height: 19rem;
    border-bottom: 0.2rem solid #746b6e;
    padding: 0 1.5rem 1.5rem;
    margin-bottom: 1.25rem;
}

.login_wrapper .logo_wrapper p {
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.15;
    text-transform: uppercase;
    color: #746b6e;
    width: 70%;
    margin: auto;
}

.login_wrapper .ant-form .ant-form-item {
    display: block;
}

.login_wrapper .ant-form .ant-form-item .ant-form-item-label {
    display: block;
    text-align: left;
    margin-bottom: 0.5rem;
}

.login_wrapper .ant-form .ant-form-item .ant-form-item-label label {
    height: auto;
    margin: 0;
}


/*admin login page css end*/



@media (max-width: 575px) {
    .cm-passfail .ant-form-item-explain-error,
    .cm-dataLocation .ant-form-item-explain-error,
    .cm-MapData .ant-form-item-explain-error {
        font-size: 9px !important;
    }
    .cm-filter-wrapper .ant-form-item:first-child {
        padding-left: 1rem;
    }
    .cm-filter-wrapper .ant-form-item:last-child {
        padding-right: 1rem;
    }
    .cm-country-form .ant-form-item .ant-form-item-label>label {
        margin-bottom: 0;
    }
}

